import axios from 'axios'
import { apiBaseUrl } from '@/variables/localVariables'

export class APINewsletterService {

  registerNewsletterSubscriber (data) {
    const url = apiBaseUrl + `/newsletter-subscribers/`
    const headers = {
      'Content-Type': 'application/json',
    }
    return axios.post(
      url,
      data,
      {
        'headers': headers,
      }
    )
  }
}
