<template>
  <div class="CreateNewsletterSubscriber" >
    <b-form v-on:submit.prevent="createSubscriber()">
      <div class="row">
        <div class="col-12 text-center">
          <p>
            <strong> Vous n'avez pas trouvé d'entreprise ? </strong>
          </p>
          <p>
            Renseignez les informations du type d'entreprise que vous recherchez <br>et recevez des offres personnalisées !
          </p>
        </div>
      </div>

      <div
        class="gavc-field">
        <label class="gavc-label" for="registration-step-1-subscriber.activity-input">
          Activité de l'entreprise
          <span class="gavc-required"> * </span>
        </label>
        <select class="gavc-select" v-model="subscriber.activity" name="registration-step-1-subscriber.activity-input">
          <option v-for="option in activityList" :key="option.value" class="gavc-select-option" :value="option.value">
            {{option.text}}
          </option>
        </select>
        <inputErrorValidationMessage
          v-if="$v.subscriber.activity.$dirty ? $v.subscriber.activity.$error : null"
          message="Veuillez renseigner cette information"
        />
      </div>

      <div
        class="gavc-field mt-3"
        v-bind:class="{ 'is-error': subscriber.locality && $v.subscriber.locality.$dirty ? $v.subscriber.locality.$error : null  }"
      >
        <label class="gavc-label" for="registration-step-1-subscriber.locality-group">
            Localisation(s) de l'entreprise
            <span class="gavc-required"> * </span>
        </label>
        <input
          class="gavc-input"
          id="registration-step-1-subscriber.locality-group"
          maxlength="250"
          @input="$v.subscriber.locality.$touch()"
          type="text"
          v-model="subscriber.locality"
        >
        <inputErrorValidationMessage
          v-if="$v.subscriber.locality.$dirty ? $v.subscriber.locality.$error : null"
          message="Veuillez renseigner cette information (entre 2 et 250 caractères"
        />
      </div>

      <div
        class="gavc-field"
        v-bind:class="{ 'is-error': subscriber.email && $v.subscriber.email.$dirty ? $v.subscriber.email.$error : null  }"
      >
        <label class="gavc-label" for="registration-step-1-subscriber.email-group">
          Email :
          <span class="gavc-required"> * </span>
        </label>
        <input
          class="gavc-input"
          id="registration-step-1-cedantUserUsername-group"
          maxlength="150"
          @input="$v.subscriber.email.$touch()"
          type="email"
          placeholder="jon@donym.fr"
          v-model="subscriber.email"
        >
        <inputErrorValidationMessage
          v-if="$v.subscriber.email.$dirty ? $v.subscriber.email.$error : null"
          message="Veuillez renseigner un email valide"
        />
      </div>

      <div
        class="gavc-field mt-2"
        v-bind:class="{ 'is-error': $v.subscriber.phone_number.$invalid }"
      >
        <label class="gavc-label" for="registration-step-1-subscriber.phone_number-group">
          Téléphone mobile
        </label>
        <input
          class="gavc-input"
          id="registration-step-1-subscriber.phone_number-group"
          maxlength="10"
          @input="$v.subscriber.phone_number.$touch()"
          type="tel"
          placeholder="06 __ __ __ __"
          v-model="subscriber.phone_number"
        >
        <inputErrorValidationMessage
          v-if="$v.subscriber.phone_number.$invalid"
          message="Votre numéro de téléphone doit être composé de 10 chiffres"
        />
      </div>

      <div class="gavc-field">
        <div class="gavc-radio-checkbox">
          <div class="gavc-checkbox">
            <input 
              v-model="subscriber.authorize_sending_offers" 
              type="checkbox" 
              id="registration-step-5-authorize_sending_offers-group" 
              name="checkbox-authorize_sending_offers"
            >
            <label class="gavc-label" for="registration-step-5-authorize_sending_offers-group">
              J’autorise Garance à vos côtés à m’envoyer les dernières offres liées à mes recherches
              <i class="gavc-icon gavc-icon-check" ></i>
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 text-center">
          <button
            class="gavc-btn"
            type="submit"
            :disabled="isInvalidSubscriber">
              S'inscrire 
          </button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength} from 'vuelidate/lib/validators'

import { APINewsletterService } from '@/api/APINewsletter'

const apiNewsletterService = new APINewsletterService()
const inputErrorValidationMessage = () => import('@/components/inputs/inputErrorValidationMessage')


export default {
  name: 'CreateNewsletterSubscriber',
  components: {
    inputErrorValidationMessage
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {
      subscriber: {
        authorize_sending_offers: false,
        email: '',
        phone_number: '',
        activity: '',
        locality: ''
      },
      activityList: [
        {
          "value": "Restauration",
          "text": "Restauration"
        },
        {
          "value": "Commerce",
          "text": "Commerce"
        },
        {
          "value": "Alimentation",
          "text": "Alimentation​"
        },
        {
          "value": "Construction et travaux",
          "text": "Construction et travaux"
        },
        {
          "value": "Services à la personne",
          "text": "Services à la personne"
        },
        {
          "value": "Automobile et transport",
          "text": "Automobile et transport"
        },
        {
          "value": "Achat et revente",
          "text": "Achat et revente"
        },
        {
          "value": "Vente en ligne",
          "text": "Vente en ligne"
        },
        {
          "value": "Consultant / freelance",
          "text": "Consultant / freelance"
        },
        {
          "value": "Autres",
          "text": "Autres"
        }
      ]
    }
  },
  computed: {
    isInvalidSubscriber () {
      if (this.$v.$invalid | !this.subscriber.authorize_sending_offers) {
        return true
      }
      return false
    }
  },
  validations: {
    subscriber: {
        activity: {
        required,
      },
      authorize_sending_offers: {
        required
      },
      locality: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(250)
      },
      email: {
        required,
        email,
        minLength: minLength(2),
        maxLength: maxLength(250)
      },
      phone_number: {
        minLength: minLength(10),
        maxLength: maxLength(10)
      }
    }    
  },
  methods: {
    createSubscriber () {
      this.$store.commit('loadFromAPI')
      apiNewsletterService.registerNewsletterSubscriber(this.subscriber)
      .then(() => {
        this.$emit('registered')
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('CreateCandidature createCandidature API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })

    },
  }
}
</script>
